




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import insurerPlanModel from "@/api/insurer_plan.model";

@Component({
	components: { EntityCrud },
})
export default class InsurerPlans extends Vue {
	model: any = insurerPlanModel;

	title = "Planos";
	tableColumns = ["name", { key: "corporateClient", name: "Seguradora" }];

	formStyleVariant = "primary";

	formColumns = [
		"name",
		"description",
		"emergencyService",
		{
			key: "emergencyServiceNumber",
			name: "Número do serviço de emergência",
			isReadOnly: false,
			validationToShow: (entity: any) => {
				return entity.emergencyService;
			},
		},
		"corporateClient",
	];
}
